import AOS from 'aos';
import 'owl.carousel';
import 'magnific-popup';
import 'select2';
require("./fslightbox");

import common from './modules/common';
import nav from './modules/nav';
import felds_board from './modules/felds_board';

import block_common from './modules/block_common';
import block_video from './modules/block_video';
import block_faq from './modules/block_faq';
import block_featured_shows from './modules/block_featured_shows';
import block_gallery from './modules/block_gallery';
import block_banner from './modules/block_banner';
import block_faq_anchors from './modules/block_faq_anchors';

jQuery( document ).ready(function($) {
  
  common();
  nav();
  felds_board();

  // Blocks
  block_common();
  block_video();
  block_faq();
  block_faq_anchors();
  // block_featured_shows();
  block_gallery();
  block_banner();

  window.felds_board = felds_board;
  window.featured_shows = block_featured_shows;

  // AOS init
  AOS.init({
    duration: 1000,
    once: true
  });
});
