import { isMobile } from "./common";

export default function block_video() {
  $('.felds-video-block .video-buttons').click(function(e){
    e.preventDefault();
    
    var _wrapper = $(this).parent();

    if (_wrapper.hasClass('felds-video-file')) {
      var _videoD = _wrapper.find('> .video-desktop video')[0];
      var _videoM = _wrapper.find('> .video-mobile video')[0];
      var _video;

      if (isMobile()) {
        _videoD.pause();
        _video = _videoM;
      } else {
        _videoM.pause();
        _video = _videoD;
      }

      if (_video.paused) {
        _video.play(); 
      } else {
        _video.pause();
      }
      
      $(this).toggleClass('playing');
    } else {
      $(this).hide();

      var _youtube = _wrapper.find('> .video-desktop .youtube-video-wrapper');
      if (isMobile()) {
        _youtube = _wrapper.find('> .video-mobile .youtube-video-wrapper');
      }

      var _iframe = _youtube.find('iframe');
      _iframe.attr('src', _iframe.data('url'));

      _youtube.addClass('playing');
    }
  });
}
