export default function felds_board() {

  $('.felds-board-tickets .engagement .btn-preview').click(function(e){
    e.preventDefault();

    var eg = $(this).closest('.engagement');
    var text = $(this).find('span');

    if (!eg.hasClass('open')) {
      $('.felds-board-tickets .engagement').removeClass('open').find('.engagement-preview').hide();
      $('.felds-board-tickets .engagement .btn-preview span').html($(this).data('view-title'));
    }
    
    eg.find('.engagement-preview').slideToggle();
    eg.toggleClass('open');

    if (eg.hasClass('open')) {
      text.html($(this).data('close-title'));
    } else {
      text.html($(this).data('view-title'));
    }
  });

  $('.felds-board-form input[name=search]').autocomplete({
		source: $('.felds-board-form input[name=search]').data('source'),
		minLength: 2,
		select: function( event, ui ) {
			search_engagements(ui.item.value);
		},
		appendTo: "#felds-citylist"
	});

	$('.felds-board-form input[name=search]').keyup(function(e){
		var s = $(this).val();
		
		if (s == '' || (s[0] >= 0 && s[0] <= 9 && s.length == 5)) {
			search_engagements(s);
		}
	});

  var filtered_ids = [];

	function search_engagements(location) {
    var search_url = $('.felds-board-form').data('url');
    var params = [];

    params.push( 'felds_location=' + location );

    $.ajax({
      url: search_url + '&' + params.join('&'),
      type: 'GET',
      dataType: 'JSON',
      success: function(res) {
        filtered_ids = res;
        filter_engagements();
      }
    });
  }

  $('.felds-board-form select').select2();

  $('.felds-board-form select').change(function(){
    filter_engagements();
  });

  function filter_engagements() {
    if ($('#felds-sort').length == 0 || $('#felds-show').length == 0) return;
    
    var $wrapper = $('.felds-board-container .felds-board-tickets');
    var sort = $('#felds-sort').val();
    var show = $('#felds-show').val();

    $wrapper.find('.engagement').show();

    if (filtered_ids.length > 0) {
      $wrapper.find('.engagement').each(function(){
        if (!filtered_ids.includes($(this).data('id'))) {
          $(this).hide();
        }
      });
    }

    if (show != '') {
      $wrapper.find('.engagement').each(function(){
        if ($(this).data('show') != show) {
          $(this).hide();
        }
      });
    }

    $wrapper.find('.engagement').sort(function (a, b) {
      return $(a).data(sort) < $(b).data(sort) ? -1 : 1;
    })
    .appendTo( $wrapper );
  }

  filter_engagements();
}
