export default function block_gallery() {
  
  $('.felds-gallery-block .gallery-container').addClass('owl-carousel');

  $('.felds-gallery-block').each(function(){
    $(this).find('.gallery-container').owlCarousel({
      items: 2,
      margin: 0,
      autoplay: false,
      slideTransition: 'linear',
      smartSpeed: 500,
      mouseDrag: true,
      touchDrag: true,
      loop: true,
      nav: true,
      navText: ['<i class="far fa-angle-left"></i>','<i class="far fa-angle-right"></i>'],
      responsive: {
        768: {
          items: 3
        },
        992: {
          items: 4
        },
      }
    });
  });

  if (fsLightbox) {
    fsLightbox.props.showThumbsOnMount = true;
  }
}
