export default function block_banner() {
  $('.felds-banner-block__gallery').each(function(){
		$(this).magnificPopup({
			delegate: 'a', // the selector for gallery item
			type: 'image',
			closeMarkup: '<a class="mfp-close" href="javascript:void(0)"><i class="fal fa-times"></i></a><a class="mfp-download" download href="#"><i class="fal fa-arrow-to-bottom"></i></a>',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0, 2],
				arrowMarkup: '<button class="btn-%dir% fal fa-arrow-%dir%"></button>',
			},
			callbacks: {
				imageLoadComplete: function(item) {
					$('.mfp-download').attr('href', $('.mfp-img').attr('src'));
				}
			}
		});
	});

	$(document).on('click', '.mfp-close', function(e){
		e.preventDefault();
		$.magnificPopup.close();
	})

	$('.felds-banner-block .btn-gallery').click(function(e){
		e.preventDefault();

		$(this).parents('.felds-banner-block').find('.felds-banner-block__gallery a').first().click();
	});

	$('.banner-extra-open').click(function(e){
		e.preventDefault();
		var banner = $($(this).attr('href'));
		banner.addClass('felds-banner-extra-opened');
		banner.find('.felds-banner-extra').slideDown();
	});

	$('.banner-extra-close').click(function(e){
		e.preventDefault();
		var banner = $($(this).attr('href'));
		banner.removeClass('felds-banner-extra-opened');
		banner.find('.felds-banner-extra').slideUp();
	});
}
